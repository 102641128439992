'use client';

import { usePathname } from "next/navigation";
import Script from "next/script";

export default function MicrosoftClarity({ NEXT_MODE }: { NEXT_MODE?: string }) {
  const pathname = usePathname();
  const hasTools = pathname?.startsWith('/iframe') ?? false;

  if (NEXT_MODE !== 'production' || hasTools) {
    return null;
  }
  
  return (
    <Script
      id="mirosoft-clarity-code"
      strategy="afterInteractive"
      dangerouslySetInnerHTML={{
        __html: `(function(c,l,a,r,i,t,y){ c[a]=c[a]||function(){(c[a].q=c[a].q||[]).push(arguments)}; t=l.createElement(r);t.async=1;t.src="https://www.clarity.ms/tag/"+i; y=l.getElementsByTagName(r)[0];y.parentNode.insertBefore(t,y); })(window, document, "clarity", "script", "os8vzurdbg");`,
    }}/>
  )
}