'use client';

import { usePathname } from "next/navigation";
import Script from "next/script";

export default function CrispChat({ NEXT_MODE }: { NEXT_MODE?: string }) {
  const pathname = usePathname();
  const hasTools = pathname?.startsWith('/iframe') ?? false;

  if (NEXT_MODE !== 'production' || hasTools) {
    return null;
  }
  
  return (
    <Script
      id="crisp-chat"
      strategy="afterInteractive"
      dangerouslySetInnerHTML={{
        __html: `window.$crisp=[];window.CRISP_WEBSITE_ID="a40238fa-e7ba-4489-937f-c8f7167d5bca";(function(){d=document;s=d.createElement("script");s.src="https://client.crisp.chat/l.js";s.async=1;d.getElementsByTagName("head")[0].appendChild(s);})();`,
      }}
    />
  )
}